import React from "react";
import Typography from "@mui/material/Typography";

import cssStyles from "./About.module.scss";

export function getAboutText() {
  return (
    <div className={cssStyles.about}>
      <Typography variant="body1" paragraph>
        A truly authentic street food experience.
      </Typography>
      <Typography variant="body1" paragraph>
        All our food ingredients are uniquely sourced from Malaysia, Thailand
        and other parts of South East Asia. The exotic flavours will lighten up
        any palate, accompanied by fine wine or an Asian beer makes this the
        best place to eat in Bromley Town. This is Papayaya.
      </Typography>
    </div>
  );
}

export function getAdditionalAboutText() {
  return null;
}

export function getAccolades() {
  return [];
}

export function getGalleryMenuImages() {
  return [];
}

export function getGalleryRestaurantImages() {
  return [];
}
